/*eslint-disable no-unused-vars*/

import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
/*eslint-enable no-unused-vars*/


const headerStyle = {
  background:
    'linear-gradient(90deg, rgba(235,92,40,1) 0%, rgba(235,92,40,1) 25%, rgba(235,92,40,1) 62%, rgba(235,92,40,1) 100%)',
  color: 'white',
  'alignItems': 'center',
};

const textStyle = {
  'fontSize': '2em',
  'fontWeight': 'bolder'
};

const HeaderBar = ({ title }) => (
  <div className="header">
    <AppBar position="static" color="default" style={headerStyle}>
      <Toolbar>
        <Typography variant="h4" color="inherit" style={textStyle}>
          {title.pageTitle || 'Page Title Placeholder'}
        </Typography>
      </Toolbar>
    </AppBar>
  </div>
);

HeaderBar.propTypes = {
  title: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
  }),
};

HeaderBar.defaultProps = {
  title: {
    pageTitle: 'Page Title Placeholder',
  },
};

export default HeaderBar;
