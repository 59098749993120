import Routes from '../Routes';
import React from 'react';




const App = () => (
  <div className="App">    
    <Routes />
  </div>
);

export default App;
