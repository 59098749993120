import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import '../forgotPassword.scss';
import axios from 'axios';
import HeaderBar from '../../components/HeaderBar';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';


class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			host: process.env.REACT_APP_APPCO_API_HOST,
			method: process.env.REACT_APP_APPCO_API_PROTOCOL,
			email: '',
			showError: false,
			messageFromServer: '',
			showNullError: false,
			pageTitle: 'Marathon of Riyadh',
		};
	}

  
	handleChange = name => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	sendEmail = async (e) => {
		e.preventDefault();
		const { email } = this.state;
		if (email === '') {
			this.setState({
				showError: false,
				messageFromServer: '',
				showNullError: true,
			});
		} else {
			try {
				const response = await axios.post(
					this.state.method + '://' + this.state.host + '/api/runners/reset_password',
					{
						email,
						sku_trial:'Riyadh2021',
					},
				);
				if (response.data.status === 'Email envoyé') {
					this.setState({
						showError: false,
						messageFromServer: 'Email envoyé',
						showNullError: false,
					});
				}
			} catch (error) {
				if (error.response.data.error.message === 'email is unknown') {
					this.setState({
						showError: true,
						messageFromServer: '',
						showNullError: false,
					});
				}
			}
		}
	};

	render() { 
		const {
			email, messageFromServer, showNullError, showError 
		} = this.state;

		return (
			<div>
				<HeaderBar title={this.state}/> 
				<div className='Recover align-items-center'>
					<div className='offset-md-4 col-md-4'>
						<section>
							<h3 className='title'>Reset password</h3>
							<form onSubmit={this.sendEmail}>
								<div className="subtitle">Enter the email associated with your account. We will send you an email with a link to reset your password.</div>
								<TextField className='inputemail'
									id="email"
									label="Enter your email address"
									value={email}
									onChange={this.handleChange('email')}
									placeholder="Email Address"
								/>
								<div className='form-group submit-button'>
									<button type="submit" className="btn btn-primary">Reset password</button>
								</div>
							</form>
							{showNullError && (
								<div>
									<p>The email address cannot be null.</p>
								</div>
							)}
							{showError && (
								<div>
									<p>
                            That email address isn&apos;t recognized. Please try again or
                            register for a new account.
									</p>
								</div>
							)}
							{messageFromServer === 'Email envoyé' && (
								<div>
									<h3>Password Reset Email Successfully Sent!</h3>
									<Redirect to={'/forgotpasswordcoderiyadh/' + this.state.email}/>
								</div>
                        
							)}
						</section>
					</div>
				</div>-
			</div>
		);
	}
}

export default ForgotPassword;
