/* eslint-disable no-console */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import '../forgotPassword.scss';
import axios from 'axios';
import HeaderBar from '../../components/HeaderBar';
import { Redirect } from 'react-router-dom';

class ForgotPasswordCode extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
			host: process.env.REACT_APP_APPCO_API_HOST,
			method: process.env.REACT_APP_APPCO_API_PROTOCOL,
			code: '',
			showError: false,
			messageFromServer: '',
			showNullError: false,
			acces_token: '',
			pageTitle: 'Marathon of Riyadh',
		};
	}

	handleChange = name => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	checkCode = async (e) => {
		e.preventDefault();
		const { code } = this.state;
		if (code === '') {
			this.setState({
				showError: false,
				messageFromServer: '',
				showNullError: true,
			});
		} else {
			try {
				const response = await axios.post(
					this.state.method + '://' + this.state.host +'/api/runners/reset_password/' + code,
					{
						email : this.props.match.params.email,
						sku_trial:'Riyadh2021',
					},
				);
				if (response.data.AccessToken.status === 'Access Granted') {
					this.setState({
						showError: false,
						messageFromServer: 'Access Granted',
						acces_token: response.data.AccessToken.access_token,
						showNullError: false,
					});
				}
			} catch (error) {
				if (error.response.data.error.message === 'Incorrect code') {
					this.setState({
						showError: true,
						messageFromServer: '',
						showNullError: false,
					});
				}
			}
		}
	};

	render() { 
		const {
			code, messageFromServer, showNullError, showError
		} = this.state;

		return (
			<div>
				<HeaderBar title={this.state}/>
				<div className='Recover align-items-center'>
					<div className='offset-md-4 col-md-4'>
						<section>
							<h3 className='title'>Reset password</h3>
							<form onSubmit={this.checkCode}>
								<div className="subtitle">Enter the code you received by email.</div>
								<TextField
									id="code"
									label="Enter your code"
									value={code}
									onChange={this.handleChange('code')}
									placeholder="Code"
								/>
								<div className='form-group submit-button'>
									<button type="submit" className="btn btn-primary">Send</button>
								</div>
							</form>
							{showNullError && (
								<div>
									<p>Code cannot be null.</p>
								</div>
							)}
							{showError && (
								<div>
									<p>
                            Invalid code, please enter a valid code.
									</p>
								</div>
							)}
							{messageFromServer === 'Access Granted' && (
								<div>
									<h3>Valid code enter password.</h3>
									<Redirect to={'/reset_password_riyadh/' + this.state.acces_token}/>
								</div>
							)}
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export default ForgotPasswordCode;
