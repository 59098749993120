/* eslint-disable no-console */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import '../forgotPassword.scss';
import axios from 'axios';
import HeaderBar from '../../components/HeaderBar';

class ResetPassword extends Component {
	constructor() {
		super();

		this.state = {
			host: process.env.REACT_APP_APPCO_API_HOST,
			method: process.env.REACT_APP_APPCO_API_PROTOCOL,
			password_confirmation: '',
			showError: false,
			messageFromServer: '',
			showNullError: false,
			pageTitle: 'Marathon of Riyadh',
		};
	}

	handleChange = name => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	changepassword = async (e) => {
		e.preventDefault();
		const { password } = this.state;
		const { password_confirmation } = this.state;
		if (password === '' || password_confirmation === '') {
			this.setState({
				showError: false,
				messageFromServer: '',
				showNullError: true,
			});
		} else {
			if (password !== password_confirmation) {
				this.setState({
					showError: false,
					messageFromServer: 'different password',
					showNullError: false,
				});
			}else {
				try {
					const response = await axios.post(
						this.state.method + '://'+ this.state.host +'/api/runners/reset_password_tok/' + this.props.match.params.token,
						{
							password : this.state.password,
							password_confirmation : this.state.password_confirmation
						},
					);
					if (response.data.status === 'Success: Password reset completed') {
						this.setState({
							showError: false,
							messageFromServer: 'Access Granted',
							showNullError: false,
						});
					}
				} catch (error) {
					if (error.response.data) {
						this.setState({
							showError: true,
							messageFromServer: '',
							showNullError: false,
						});
					}
				}
			}
		}
	};

	render() { 
		const {
			password, password_confirmation, messageFromServer, showNullError, showError 
		} = this.state;

		return (
			<div>
				<HeaderBar title={this.state}/>
				<div className='Recover align-items-center'>
					<div className='offset-md-4 col-md-4'>
						<section>
							<h3 className='title'>Reset password</h3>
							<form onSubmit={this.changepassword}>
								<div className="subtitle">Enter new password</div>
								<TextField
									id="password"
									label="New password"
									value={password}
									onChange={this.handleChange('password')}
									placeholder="Enter new password"
									type="password"
								/>
								<br/>
								<TextField
									id="passwordconfirmation"
									label="Confirm password"
									value={password_confirmation}
									onChange={this.handleChange('password_confirmation')}
									placeholder="Confirm Password"
									type="password"
								/>
								<div className='form-group submit-button'>
									<button type="submit" className="btn btn-primary">Envoyer</button>
								</div>
							</form>
							{showNullError && (
								<div>
									<p>Password cannot be null.</p>
								</div>
							)}
							{showError && (
								<div>
									<p>
                            Error, try again.
									</p>
								</div>
							)}
							{messageFromServer === 'different password' && (
								<div>
									<p>Password and password confirmation are different</p>
								</div>
							)}
							{messageFromServer === 'Access Granted' && (
								<div>
									<h3>Password changed successfully! You can now close this window.</h3>
								</div>
							)}
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
