import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import ForgotPassword from './containers/forgotPassword/forgotPassword';
import ForgotPasswordCode from './containers/forgotPasswordCode/forgotPassWordCode';
import ResetPassword from './containers/resetPassword/resetPassword';


const Routes = () => (
  <div>
    <Switch>
      <Route exact path="/forgotpasswordcoderiyadh/:email" component={ForgotPasswordCode}/>
      <Route exact path="/reset_password_riyadh/:token" component={ResetPassword}/>
      <Route exact path="/resetpassword_riyadh" component={ForgotPassword}/>
      <Redirect from="/" to="/resetpassword_riyadh"/>
      <Route path="*" exact={true} component={ForgotPassword}/>
    </Switch>
  </div>
);

export default Routes;
