/*eslint-disable no-unused-vars*/

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';

/*eslint-enable no-unused-vars*/


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

